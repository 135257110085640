import axios from "axios";

const RESSOURCE_NAME = "secteurs";

export default {
  getHoraires(id_secteur) {
    return axios.post(`${RESSOURCE_NAME}/horaires/`,
      { id_secteur: id_secteur}
    );
  },
  getCommunes(id_secteur, query = "", page) {
    return axios.post(
      `${RESSOURCE_NAME}/communes/${id_secteur}?page=${page}&size=5&orderBy=commune&sortBy=ASC`,
      { query: query, page: page }
    );
  },
  getAllCommunes(query = "", page, id_secteur) {
    return axios.post(
      `${RESSOURCE_NAME}/allcommunes/${id_secteur}?page=${page}&size=5&orderBy=commune&sortBy=ASC`,
      { query: query, page: page }
    );
  },

  deleteCommune(id_secteur, id_commune) {
    return axios.delete(
      `${RESSOURCE_NAME}/commune/${id_secteur}/${id_commune}`
    );
  },
  createCommune(id_secteur, newCommune) {
    console.log(newCommune);
    return axios.post(`${RESSOURCE_NAME}/createcommune/${id_secteur}`, {
      commune: newCommune.commune,
      code_postal: newCommune.code_postal,
    });
  },
  createSecteur(newSecteur) {
    return axios.post(`${RESSOURCE_NAME}/create/`, {
      id_camion: newSecteur.id_camion,
      id_marche: newSecteur.id_marche,
      id_type_client: newSecteur.id_type_client,
      nom_secteur: newSecteur.nom_secteur,
    });
  },
  addCommune(id_commune, id_secteur) {
    return axios.post(`${RESSOURCE_NAME}/addcommunes/${id_secteur}`, {
      communes: [id_commune],
    });
  },

  getFrequence(id_secteur) {
    return axios.get(`${RESSOURCE_NAME}/frequences/${id_secteur}`);
  },

  deleteFrequence(id_frequence) {
    return axios.delete(`${RESSOURCE_NAME}/frequence/${id_frequence}`)
  },
};
